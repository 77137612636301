import dayjs, { ConfigType } from "dayjs";

import "dayjs/locale/ko";
import "dayjs/locale/en-sg";

import { initDayJS, TimeZone } from "../../services/dayjs";

import { ShipdaCurrentLanguage } from "../../i18n/i18nForShipda";
import {
  LUNAR_PUBLIC_HOLIDAY_LIST,
  PUBLIC_HOLIDAY_LIST,
  SUBSTITUTE_HOLIDAY_LIST,
} from "./constants";

initDayJS();

/**
 * 주어진 초 단위의 기간을 현재 시간에 더하여 만료 시간을 계산하는 함수입니다.
 *
 * @param durationAsSecond - 초 단위로 표현된 기간. 숫자로 변환 가능한 값이어야 합니다.
 * @returns 계산된 만료 시간(Date 객체). 유효하지 않은 입력이 주어지면 `undefined`를 반환합니다.
 *
 * @remarks
 * - `durationAsSecond`가 숫자로 변환할 수 없는 값이거나 0일 경우, 함수는 `undefined`를 반환합니다.
 * - 이 함수는 `dayjs` 라이브러리를 사용하여 날짜와 시간을 계산합니다.
 */
function getExpiredAt(durationAsSecond: number) {
  if (!durationAsSecond) {
    return;
  }

  const duration = Number(durationAsSecond);

  if (isNaN(duration)) {
    return;
  }

  const expiredAt = dayjs().add(duration, "second").toDate();

  return expiredAt;
}

/**
 * 주어진 날짜가 유효한지 확인하는 타입 가드 함수
 *  - ex) Date 생성자 인자가 Type Narrowing을 요구할 때 사용
 *
 * @param date 유효성 검사 대상 날짜
 * @returns 유효한 날짜라면 true, 그렇지 않다면 false.
 */
const checkIsValidDate = (
  date: ConfigType
): date is Exclude<ConfigType, null | undefined> => {
  if (typeof date === "undefined" || date === null) return false;

  return dayjs(date).isValid();
};

/**
 * 시간을 특정 포맷으로 변경해준다.
 *
 * @param value - 변환할 원본 시간 데이터. 문자열, Date 객체, 숫자 또는 null일 수 있다.
 * @param format - 변환하고자 하는 데이터 포맷. 기본값은 한국어("ko")일 경우 "YYYY-MM-DD hh:mm:ss a", 싱가포르 영어("en-sg")일 경우 "DD/MM/YY hh:mm:ss a"이다.
 * @param asUTC - timezone이 아니라 UTC로 받고 싶을 때 true로 설정. 기본값은 false.
 * @param usesHostTimeZone dayjs.tz의 'Asia/Seoul' TimeZone을 무시하고 dayjs 인스턴스의 default TimeZone(host인 브라우저의 TimeZone과 동일) 사용하고 싶을 때 true로 설정. 기본값은 false.
 *
 * @returns 포맷팅된 날짜 문자열. 유효하지 않은 입력이 주어지면 빈 문자열을 반환.
 *
 * @remarks
 * - `usesHostTimeZone`을 true로 설정하면, dayjs 전역의 default TimeZone을 바꿀 수 없어서 발생하는 충돌을 피할 수 있다.
 * - `asUTC`와 `usesHostTimeZone`이 모두 true일 경우, `asUTC`가 우선된다.
 */
function toFormattedDate(
  value?: string | Date | number | null,
  format = ShipdaCurrentLanguage.currentLanguage === "ko"
    ? "YYYY-MM-DD hh:mm:ss a"
    : "DD/MM/YY hh:mm:ss a",
  asUTC?: boolean,
  usesHostTimeZone?: boolean
) {
  if (!value) return "";

  const date = dayjs(value);

  if (asUTC) {
    return date.utc().format(format);
  }

  if (usesHostTimeZone) {
    return date.format(format);
  }

  return date.tz("Asia/Seoul").format(format);
}

/**
 * 시간을 utc 포맷 (ISO 형식) 으로 변환.
 *
 * @param value - 변환할 원본 시간 데이터. 문자열, Date 객체, 숫자 또는 undefined일 수 있다.
 * @returns 변환된 UTC 포맷의 ISO 문자열. 유효하지 않은 입력이 주어지면 빈 문자열을 반환.
 */
function toFormattedDateToUTCDate(value: string | Date | number | undefined) {
  if (!value) return "";

  const date = dayjs(value);

  return date.utc().toISOString();
}

/**
 * 시간을 timezone에 맞는 Date 로 변환.
 *
 * @param value - 변환할 원본 시간 데이터. 문자열, Date 객체, 숫자 또는 undefined일 수 있다.
 * @param timeZone - 변환하고자 하는 타임존.
 * @returns 변환된 타임존에 맞는 ISO 문자열. 유효하지 않은 입력이 주어지면 빈 문자열을 반환.
 */
function toFormattedDateToLocaleDate({
  value,
  timeZone,
}: {
  value: string | Date | number | undefined;
  timeZone: TimeZone;
}) {
  if (!value) return "";

  const date = dayjs(value);

  return date.tz(timeZone).toISOString();
}

/**
 * 주어진 날짜가 현재 시간 기준으로 지난 날짜인지 확인.
 * - TODO: KST 기준으로만 동작하므로, 그 외의 Timezone 대응필요시 수정해야한다.
 *
 * @param date - 비교할 날짜. Date 객체여야 한다.
 * @returns 날짜가 지났다면 true, 그렇지 않다면 false.
 */
function hasDayPassed(date: Date | string) {
  const today = dayjs(getAppTodayMidnight());

  const target = convertToKstMidnightUTC(dayjs(date).toISOString());

  // 비교 대상 날짜들을 모두 해당 날의 자정으로 환산한 후 비교한다.
  return today.diff(target, "day") >= 1;
}

/**
 * 두 날짜 사이의 남은 일수를 계산.
 * - from이 더 큰 날짜라면 음수를 반환한다.
 * - TODO: KST 기준으로만 동작하므로, 그 외의 Timezone 대응필요시 수정해야한다.
 * @param from - 시작 날짜. 문자열 또는 Date 객체일 수 있다.
 * @param to - 종료 날짜. 문자열 또는 Date 객체일 수 있다.
 * @returns 남은 일수. 유효하지 않은 입력이 주어지면 0을 반환.
 */
function getRemainedDays(from: string | Date | null, to: string | Date | null) {
  if (!from || !to) return 0;

  const fromDate = dayjs(from);
  const toDate = dayjs(to);

  if (!fromDate.isValid() || !toDate.isValid()) {
    return 0;
  }

  const midnightOfFromDate = convertToKstMidnightUTC(fromDate.toISOString());

  const midnightOfToDate = convertToKstMidnightUTC(toDate.toISOString());

  // 비교 대상 날짜들을 모두 해당 날의 자정으로 환산한 후 비교한다.
  const difference = dayjs(midnightOfToDate).diff(midnightOfFromDate, "days");

  return difference;
}

/**
 * 주어진 날짜가 오늘인지 확인.
 * - TODO: KST 기준으로만 동작하므로, 그 외의 Timezone 대응필요시 수정해야한다.
 *
 * @param value - 확인할 날짜. 문자열, Date 객체, 숫자 또는 undefined일 수 있다.
 * @returns 오늘이라면 true, 그렇지 않다면 false.
 */
function isToday(value?: string | Date | number) {
  if (!value) return false;

  const today = dayjs(getAppTodayMidnight());

  const target = convertToKstMidnightUTC(dayjs(value).toISOString());

  // 비교 대상 날짜들을 모두 해당 날의 자정으로 환산한 후 비교한다.
  const difference = today.diff(target, "days");

  return !difference;
}

/**
 * 주어진 날짜가 오늘 이전인지 확인.
 *
 * @param value - 확인할 날짜. 문자열, Date 객체, 숫자 또는 undefined일 수 있다.
 * @returns 오늘 이전이라면 true, 그렇지 않다면 false.
 */
function isBeforeToday(value?: string | Date | number) {
  if (!value) return false;

  const target = dayjs(value);
  const now = dayjs();

  const isBefore = target.isBefore(now, "day");

  return isBefore;
}

/**
 * baseDate가 targetDate보다 빠른 날짜인지 체크
 *
 * @param baseDate - 기준 날짜. 문자열, Date 객체 또는 숫자일 수 있다.
 * @param targetDate - 비교대상 날짜. 문자열, Date 객체 또는 숫자일 수 있다.
 * @param unit - 비교단위. dayjs의 단위 타입을 사용한다.
 * @returns baseDate가 targetDate보다 빠른 날짜이면 true, 그렇지 않으면 false.
 */
function isBeforeDate({
  baseDate,
  targetDate,
  unit,
}: {
  baseDate: string | Date | number;
  targetDate: string | Date | number;
  unit: dayjs.OpUnitType;
}) {
  const base = dayjs(baseDate);
  const target = dayjs(targetDate);

  return base.isBefore(target, unit);
}

/**
 * 주어진 날짜가 오늘이거나 오늘 이전인지 확인
 *
 * @param value - 확인할 날짜. 문자열, Date 객체, 숫자 또는 undefined일 수 있다.
 * @returns 오늘이거나 오늘 이전이면 true, 그렇지 않으면 false.
 */
function isTodayOrBeforeToday(value?: string | Date | number) {
  if (!value) return false;

  const target = dayjs(value);
  const today = dayjs(getAppTodayMidnight());

  const isBefore = target.diff(today, "day") <= 0;

  return isBefore;
}

/**
 * 두 날짜가 같은 날인지 확인
 *
 * @param date1 - 첫 번째 날짜. dayjs의 ConfigType을 사용한다.
 * @param date2 - 두 번째 날짜. dayjs의 ConfigType을 사용한다.
 * @returns 두 날짜가 같은 날이면 true, 그렇지 않으면 false.
 */
const isSameDay = (date1: dayjs.ConfigType, date2: dayjs.ConfigType) => {
  return dayjs(date1).isSame(date2, "day");
};

/**
 * 주어진 날짜가 평일인지 확인
 *
 * @param date - 확인할 날짜. Date 객체여야 한다.
 * @returns 평일이면 true, 주말이면 false.
 */
const isWeekday = (date: Date) => {
  const day = date.getDay();

  return day === 0 || day === 6;
};

/**
 * 공휴일 목록을 반환
 *
 * @returns 공휴일 목록 배열. 각 항목은 문자열 형식의 날짜이다.
 */
function getHolidayList() {
  const yearArr = new Array(5)
    .fill(0)
    .map((v, i) => new Date().getFullYear() + i);

  const holidayListWithYear = yearArr
    .map((year) => PUBLIC_HOLIDAY_LIST.map((date) => `${year}/${date}`))
    .reduce((acc, items) => {
      return acc.concat(items);
    }, []);

  return [
    ...holidayListWithYear,
    ...LUNAR_PUBLIC_HOLIDAY_LIST,
    ...SUBSTITUTE_HOLIDAY_LIST,
  ];
}

/**
 * 1분을 밀리초로 변환한 상수
 */
const MINUTE_AS_MILLISECONDS = 1000 * 60;

/**
 * 1시간을 밀리초로 변환한 상수
 */
const HOUR_AS_MILLISECONDS = MINUTE_AS_MILLISECONDS * 60;

/**
 * 1일을 밀리초로 변환한 상수
 */
const DAY_AS_MILLISECONDS = HOUR_AS_MILLISECONDS * 24;

/**
 * 1년을 밀리초로 변환한 상수
 */
const YEAR_AS_MILLISECONDS = DAY_AS_MILLISECONDS * 365;

/**
 * 주어진 날짜를 Unix 시간으로 변환하는 함수
 *
 * @param date - 변환할 날짜. Date 객체여야 한다.
 * @param type - 변환할 Unix 시간의 타입. "seconds" 또는 "milliseconds" 중 하나여야 한다.
 * @returns 변환된 Unix 시간. 초 단위 또는 밀리초 단위의 숫자.
 *
 * @remarks
 * - type이 "seconds"인 경우, Unix 시간을 초 단위로 반환한다.
 * - type이 "milliseconds"인 경우, Unix 시간을 밀리초 단위로 반환한다.
 */
function getUnixTime(date: Date, type: "seconds" | "milliseconds") {
  if (type === "seconds") {
    return date.getTime() / 1000;
  }

  if (type === "milliseconds") {
    return date.getTime();
  }
}

/**
 * 오늘 날짜를 한국어 로케일 형식의 문자열로 반환하는 함수
 *
 * @returns 한국어 로케일 형식의 오늘 날짜 문자열.
 */
const getTodayDateToLocaleDateStringKr = () => {
  const date = new Date();
  return date.toLocaleDateString("ko-KR");
};

/**
 * UTC 날짜 또는 문자열을 주어진 타임존의 로컬 날짜로 변환하는 함수
 * - 시간 부분은 무시되고 날짜 부분만 고려된다.
 *
 * @param utcDateTime - 변환할 UTC 날짜 또는 문자열.
 * @param timeZone - 변환할 타임존.
 * @param when - 변환할 시점. "start"는 시작 시간을, "end"는 종료 시간을 의미한다.
 * @returns 변환된 로컬 날짜. 유효하지 않은 입력이 주어지면 `undefined`를 반환.
 *
 * @remarks
 * - `when`이 "start"인 경우, 해당 날짜의 시작 시간을 반환한다.
 * - `when`이 "end"인 경우, 해당 날짜의 종료 시간을 반환한다.
 */
function transformUTCDateToLocalDateTime({
  utcDateTime,
  timeZone,
  when,
}: {
  utcDateTime: Date | string;
  timeZone: TimeZone;
  when: "start" | "end";
}): Date | undefined {
  if (!(utcDateTime && timeZone && when)) return;

  const dayByTimeZone = dayjs.tz(utcDateTime, timeZone);

  if (when === "start") {
    return dayByTimeZone.startOf("day").toDate();
  }

  if (when === "end") {
    return dayByTimeZone.endOf("day").toDate();
  }
}

/**
 * 현재 연도를 두 자리 숫자로 반환하는 상수
 *
 * @returns 현재 연도의 두 자리 숫자 문자열.
 */
const THIS_YEAR_AS_TWO_DIGITS = (() => {
  const thisYear = new Date().getFullYear().toString();
  return thisYear.substring(2, 4);
})();

/**
 * 주어진 날짜가 유효한지 확인하는 함수
 *
 * @param date - 확인할 날짜. Date 객체 또는 문자열일 수 있다.
 * @returns 유효하지 않은 날짜이면 true, 그렇지 않으면 false.
 */
function isInvalidDate(date: Date | string) {
  if (date instanceof Date && isNaN(date.valueOf())) {
    return true;
  }
  return false;
}

/**
 * 주어진 날짜에 특정 기간을 더하는 함수
 *
 * @param date - 기준 날짜. 문자열, Date 객체 또는 숫자일 수 있다.
 * @param value - 더할 기간의 값. 숫자여야 한다.
 * @param unit - 더할 기간의 단위. dayjs의 ManipulateType을 사용한다.
 * @returns 기간이 더해진 새로운 날짜(Date 객체).
 */
function addDate({
  date,
  value,
  unit,
}: {
  date: string | Date | number;
  value: number;
  unit: dayjs.ManipulateType;
}) {
  const target = dayjs(date);

  return target.add(value, unit).toDate();
}

/**
 * 주어진 날짜에서 특정 기간을 빼는 함수
 *
 * @param date - 기준 날짜. 문자열, Date 객체 또는 숫자일 수 있다.
 * @param value -  뺄 기간의 값. 숫자여야 한다.
 * @param unit - 뺄 기간의 단위. dayjs의 ManipulateType을 사용한다.
 * @returns 기간이 뺀 새로운 날짜(Date 객체).
 */
function subtractDate({
  date,
  value,
  unit,
}: {
  date: string | Date | number;
  value: number;
  unit: dayjs.ManipulateType;
}) {
  const target = dayjs(date);

  return target.subtract(value, unit).toDate();
}

/**
 * 오늘 날짜로부터 해당 월의 시작 날짜와 오늘 날짜를 반환하는 함수
 *
 * @param date - 기준 날짜. 문자열 형식이어야 한다.
 * @returns 시작 날짜와 오늘 날짜를 포함한 객체. { startDate: string, endDate: string }
 */
function getMonthFromToday(date: string) {
  const today = dayjs(date).format();
  const startOfMonth = dayjs().startOf("month").format();
  return { startDate: startOfMonth, endDate: today };
}

/**
 * 주어진 날짜가 오늘과 같거나 이전인지 확인하는 함수
 *
 * @param value - 확인할 날짜. dayjs의 ConfigType을 사용한다.
 * @returns 오늘과 같거나 이전이면 true, 그렇지 않으면 false.
 */
const isSameOrBeforeToday = (value: dayjs.ConfigType) => {
  if (!value) {
    return false;
  }

  const target = dayjs(value);
  const today = dayjs().endOf("day");

  return target.isSameOrBefore(today);
};

/**
 * 현재 시간이 해당 날짜의 자정 이전인지 여부를 반환하는 함수
 *
 * @param value - 확인할 날짜. dayjs의 ConfigType을 사용한다.
 * @returns 자정 이전이면 true, 그렇지 않으면 false.
 */
const isSameOrBeforeEndOfDate = (value: dayjs.ConfigType) => {
  if (!value) {
    return false;
  }

  const now = dayjs().tz("Asia/Seoul");
  const endOfDate = dayjs(value).tz("Asia/Seoul").endOf("date");

  return now.isSameOrBefore(endOfDate);
};

/**
 * 주어진 날짜가 주말인지 확인하는 함수
 *
 * @param date - 확인할 날짜. dayjs의 ConfigType을 사용한다.
 * @returns 주말이면 true, 그렇지 않으면 false.
 */
const isWeekend = (date: dayjs.ConfigType) => {
  const day = dayjs(date).day();

  return day === 0 || day === 6;
};

/**
 * 주어진 날짜가 토요일인지 확인하는 함수
 *
 * @param date - 확인할 날짜. dayjs의 ConfigType을 사용한다.
 * @returns 토요일이면 true, 그렇지 않으면 false.
 */
const isSaturday = (date: dayjs.ConfigType) => {
  const day = dayjs(date).day();

  return day === 6;
};

/**
 * 주어진 날짜가 공휴일인지 확인하는 함수
 *
 * @param date - 확인할 날짜. dayjs의 ConfigType을 사용한다.
 * @returns 공휴일이면 true, 그렇지 않으면 false.
 */
const isHoliday = (date: dayjs.ConfigType) =>
  getHolidayList().some((holiday) => isSameDay(date, holiday));

/**
 * 주어진 날짜가 영업일인지 확인하는 함수
 *
 * @param date - 확인할 날짜. Date 객체여야 한다.
 * @returns 영업일이면 true, 그렇지 않으면 false.
 */
const isBusinessDay = (date: Date) => !(isWeekend(date) || isHoliday(date));

/**
 * 주어진 날짜에 영업일 기준으로 특정 일수를 더하는 함수
 *
 * @param date - 기준 날짜. dayjs의 ConfigType을 사용한다.
 * @param days - 더할 영업일 수. 숫자여야 한다.
 * @returns 영업일이 더해진 새로운 날짜(Date 객체).
 */
const addBusinessDays = ({
  date,
  days,
}: {
  date: dayjs.ConfigType;
  days: number;
}) => {
  let result = date;
  let count = 0;

  while (count < days) {
    result = dayjs(result).add(1, "day").toDate();

    if (isBusinessDay(result)) {
      count++;
    }
  }

  return result;
};

/**
 * 주어진 날짜가 영업일 이후인지 확인하는 함수
 *
 * @param baseDate - 기준 날짜. dayjs의 ConfigType을 사용한다.
 * @param compareDate - 비교할 날짜. dayjs의 ConfigType을 사용한다.
 * @param days - 더할 영업일 수. 숫자여야 한다.
 * @returns 비교할 날짜가 기준 날짜로부터 주어진 영업일 수 이후인지 여부. 이후이면 true, 그렇지 않으면 false.
 */
const isAfterBusinessDays = ({
  baseDate,
  compareDate,
  days,
}: {
  baseDate: dayjs.ConfigType;
  compareDate?: dayjs.ConfigType;
  days: number;
}) => {
  const dateAfterBusinessDays = addBusinessDays({ date: baseDate, days });

  return dayjs(compareDate).isAfter(dateAfterBusinessDays);
};

/**
 * 로컬 시간을 적용하지 않고 그대로 포맷팅하는 함수
 *
 * @param value - 변환할 원본 시간 데이터. dayjs의 ConfigType을 사용한다.
 * @param format - 변환하고자 하는 데이터 포맷. 기본값은 "YYYY-MM-DD hh:mm:ss a"이다.
 * @returns 포맷팅된 날짜 문자열. 유효하지 않은 입력이 주어지면 빈 문자열을 반환.
 */
const toFormattedDateWithoutLocalTime = (
  value: dayjs.ConfigType,
  format = "YYYY-MM-DD hh:mm:ss a"
) => {
  if (!value) {
    return "";
  }

  const date = dayjs.utc(value);

  return date.format(format);
};

/**
 * 특정 format의 date string을 받아 JS Date 객체를 반환하는 함수
 * - dateFormat에 2 digit year("YY")는 사용할 수 없음에 유의
 *
 * @param date - 변환할 날짜 문자열.
 * @param dateFormat - 날짜 문자열의 포맷.
 * @returns 변환된 JS Date 객체.
 */
const toJSDate = (date: string, dateFormat: string) => {
  return dayjs(date, dateFormat).toDate();
};

/**
 * `브라우저 기준의 0시`(midnight)을 `APP_DEFAULT_TIMEZONE 기준의 0시`로 변경해주는 함수
 * - react-datepicker는 타임존 지정을 지원하지 않기때문에(항상 브라우저 타임존을 사용함), 이 함수를 이용하여 APP_DEFAULT_TIMEZONE에 맞는 0시를 가져옴
 *
 * @param browserMidnight - 브라우저 기준의 midnight인 Date (ex. KST에서 1월 2일을 선택했다면, `2024-01-02T15:00:00.000Z`인 Date )
 * @returns APP_DEFAULT_TIMEZONE 기준의 midnight인 Date.
 */
function changeBrowserMidnightToAppMidnight(browserMidnight: Date): Date {
  const date = dayjs(browserMidnight as Date);

  // APP_DEFAULT_TIMEZONE 기준의 UTC Offset
  const appDefaultTimezoneOffset = dayjs
    .tz(browserMidnight as Date)
    .utcOffset();

  /**
   * 브라우저 Timezone 기준의 UTC Offset
   * dayjs로 계산하는 appDefaultTimezoneOffset과 달리 시간이 빠르면 음수로 표현된다.
   */
  const browserTimezoneOffset = (browserMidnight as Date).getTimezoneOffset();

  const offsetDifference = appDefaultTimezoneOffset + browserTimezoneOffset;

  // 두 Timezone간 offset차이를 빼서 APP_DEFAULT_TIMEZONE기준의 midnight을 계산한다.
  const appMidnight = date
    .subtract(offsetDifference, "m")
    // timezone에서 초단위는 의미가 없으므로 초단위 이하는 0으로 셋팅한다.
    .set("s", 0)
    .set("ms", 0)
    .toDate();

  return appMidnight;
}

/**
 * `APP_DEFAULT_TIMEZONE 기준의 0시`(midnight)을 `그날의 마지막 시간`으로 변경해주는 함수
 * - 날짜 필터 중 `종료날짜`의 경우, 선택된 날짜의 마지막 시간을 가져와야 할때 사용한다.
 * - ex) `2024-01-03 (KST)`를 선택한 경우, 해당날의 0시 Date(`2024-01-02T15:00:00.000Z`)를 보내면, 그날의 마지막 시간인 `2024-01-03T14:59:59.999Z`을 반환.
 *
 * @param appMidnight - APP_DEFAULT_TIMEZONE 기준의 midnight인 Date (ex. KST에서 23년 1월 3일을 선택했다면, `2024-01-02T15:00:00.000Z`인 Date )
 * @returns 그날의 마지막 시간인 Date.
 */
function changeAppMidnightToEndOfDay(appMidnight: Date): Date {
  return dayjs(appMidnight).add(1, "d").subtract(1, "ms").toDate();
}

/**
 * `브라우저 Timezone 기준으로 오늘 0시`(midnight)를 반환하는 함수
 *
 * @returns 브라우저 Timezone 기준의 오늘 0시인 Date.
 */
function getBrowserTodayMidnight(): Date {
  const now = new Date();
  now.setHours(0, 0, 0, 0);

  return now;
}

/**
 * `APP_DEFAULT_TIMEZONE 기준으로 오늘 0시`(midnight)를 반환하는 함수
 *
 * @returns APP_DEFAULT_TIMEZONE 기준의 오늘 0시인 Date.
 */
function getAppTodayMidnight() {
  return changeBrowserMidnightToAppMidnight(getBrowserTodayMidnight());
}

/**
 * `APP_DEFAULT_TIMEZONE 기준으로 내일 0시`(midnight)를 반환하는 함수
 *
 * @returns APP_DEFAULT_TIMEZONE 기준의 내일 0시인 Date.
 */
function getAppTomorrowMidnight() {
  const appTodayMidnight = changeBrowserMidnightToAppMidnight(
    getBrowserTodayMidnight()
  );

  return dayjs(appTodayMidnight).add(1, "day").toDate();
}

/**
 * 주어진 날짜를 KST 자정으로 변환한 후 UTC 형식으로 반환하는 함수
 *
 * @param date - 변환할 날짜 문자열.
 * @returns KST 자정으로 변환된 UTC 형식의 날짜 문자열.
 */
const convertToKstMidnightUTC = (date: string) => {
  // TODO: timezone 부분을 파라미터로 받아 사용할수 수 있도록 개선 (현재는 KST로 고정된 상태라 확장성에 제한이 있음)
  const kstTime = dayjs(date).tz("Asia/Seoul");

  const kstMidnight = kstTime.startOf("day");

  return kstMidnight.utc().format();
};

export {
  checkIsValidDate,
  hasDayPassed,
  changeBrowserMidnightToAppMidnight,
  changeAppMidnightToEndOfDay,
  addDate,
  subtractDate,
  getExpiredAt,
  toFormattedDate,
  toFormattedDateToUTCDate,
  toFormattedDateToLocaleDate,
  toJSDate,
  isToday,
  isBeforeToday,
  isTodayOrBeforeToday,
  isBeforeDate,
  isSameDay,
  isWeekday,
  isSaturday,
  getHolidayList,
  getRemainedDays,
  MINUTE_AS_MILLISECONDS,
  HOUR_AS_MILLISECONDS,
  DAY_AS_MILLISECONDS,
  YEAR_AS_MILLISECONDS,
  getUnixTime,
  getTodayDateToLocaleDateStringKr,
  transformUTCDateToLocalDateTime,
  THIS_YEAR_AS_TWO_DIGITS,
  isInvalidDate,
  getMonthFromToday,
  isSameOrBeforeToday,
  isSameOrBeforeEndOfDate,
  isWeekend,
  isHoliday,
  isBusinessDay,
  addBusinessDays,
  isAfterBusinessDays,
  toFormattedDateWithoutLocalTime,
  getBrowserTodayMidnight,
  getAppTodayMidnight,
  getAppTomorrowMidnight,
  convertToKstMidnightUTC,
};
