import {
  AIR_CAUTION_LIST_FOR_EXPORTATION,
  AIR_CAUTION_LIST_FOR_IMPORTATION,
  CONSOL_CAUTION_LIST,
  OCEAN_FCL_CAUTION_LIST_FOR_EXPORTATION,
  OCEAN_FCL_CAUTION_LIST_FOR_IMPORTATION,
  OCEAN_LCL_CAUTION_LIST_FOR_EXPORTATION,
  OCEAN_LCL_CAUTION_LIST_FOR_IMPORTATION,
  OCEAN_TICKET_CAUTION_LIST,
} from "@sellernote/_shared/src/constants/forwarding/quoteCautions";

import { ShipmentType } from "../../types/forwarding/common";
import { TransportCargoType } from "../../types/forwarding/quote";

/**
 * 쉽다, 어드민 공통 견적 주의사항 (참고문서: https://www.notion.so/shipda/PDF-93f383b506b945f18ef6069de06acc54?pvs=4)
 * (쉽다 웹의 견적의뢰 주의사항, 어드민 수동견적 코멘트 내 주의사항, 견적서 PDF 내 주의사항)
 * @returns {string[]} 견적 주의사항 리스트
 */
const getCautionListByTransportCargoType = ({
  shipmentType,
  transportCargoType,
  hasShipmentInfo,
  originInfo,
  destinationInfo,
  selectedSchedule,
  isOceanTicketRoute,
}: {
  shipmentType: ShipmentType;
  transportCargoType?: TransportCargoType | "Consol";
  hasShipmentInfo: boolean;
  originInfo?: { origin: { name?: string } };
  destinationInfo?: { name?: string; code?: string; country?: string };
  selectedSchedule: boolean;
  isOceanTicketRoute: boolean;
}) => {
  const inlandCostCaution = (() => {
    if (!hasShipmentInfo) return [];

    const allowsInlandCautionPort = (() => {
      if (shipmentType === "importation")
        return (
          destinationInfo?.name === "인천항" ||
          destinationInfo?.name === "부산항"
        );

      return (
        originInfo?.origin?.name === "인천항" ||
        originInfo?.origin?.name === "부산항"
      );
    })();

    if (!allowsInlandCautionPort) return [];

    // TODO: 내륙 운송료 계산 API or 코멘트 생성 API 개발 전 임시 코멘트 문구.
    return [`인천/부산신항 접안 시 내륙운송료가 변동될 수 있습니다.`];
  })();

  // 전체 공통 주의사항
  const generalCautionList = (() => {
    return selectedSchedule
      ? [
          "안내드린 스케줄은 기상악화, 천재지변 및 선사(항공사) 등 사정에 따라 일정 변동이 있을 수 있습니다. 납기가 촉박한 화물들은 항공운송 등의 대안루트를 이용할 수 있도록 메일 회신 또는 채팅 통한 연락 부탁드립니다.",
        ]
      : [
          "납기가 촉박한 화물들은 항공운송 등의 대안루트를 이용할 수 있도록 메일 회신 또는 채팅 통한 연락 부탁드립니다.",
        ];
  })();

  // 수출 공통 주의사항
  const generalCautionOfExportationList = (() => {
    return [
      "견적서 내 회색 음영처리 행의 항목들은 VAT가 별도 발생합니다.",
      "일정 및 세부사항 변동에 따라 실제 청구되는 비용은 달라질 수 있습니다.",
    ];
  })();

  // 수출 시카고 관련 주의사항
  const chicagoCautionOfExportationList = (() => {
    // 시카고 철도역 여부
    const isDestinationOfChicagoRailCY =
      destinationInfo?.code === "USCHI" && destinationInfo.country === "US";

    if (
      shipmentType !== "exportation" ||
      !isDestinationOfChicagoRailCY ||
      transportCargoType !== "LCL"
    )
      return [];

    return [
      "북미 내륙 철송 연계 운송되는 지역(Chicago 등)의 운임톤 기준은 1CBM = 500kgs 기준 큰 값으로 적용됩니다.",
    ];
  })();

  switch (true) {
    case shipmentType === "importation" && isOceanTicketRoute:
      return [...OCEAN_TICKET_CAUTION_LIST, ...generalCautionList];

    case transportCargoType === "FCL":
      return shipmentType === "importation"
        ? [
            ...OCEAN_FCL_CAUTION_LIST_FOR_IMPORTATION,
            ...generalCautionList,
            ...inlandCostCaution,
          ]
        : [
            ...generalCautionOfExportationList,
            ...chicagoCautionOfExportationList,
            ...OCEAN_FCL_CAUTION_LIST_FOR_EXPORTATION,
            ...generalCautionList,
            ...inlandCostCaution,
          ];

    case transportCargoType === "AIR":
      return shipmentType === "importation"
        ? [...AIR_CAUTION_LIST_FOR_IMPORTATION, ...generalCautionList]
        : [
            ...generalCautionOfExportationList,
            ...chicagoCautionOfExportationList,
            ...AIR_CAUTION_LIST_FOR_EXPORTATION,
            ...generalCautionList,
          ];

    case transportCargoType === "LCL":
      return shipmentType === "importation"
        ? [...OCEAN_LCL_CAUTION_LIST_FOR_IMPORTATION, ...generalCautionList]
        : [
            ...generalCautionOfExportationList,
            ...chicagoCautionOfExportationList,
            ...OCEAN_LCL_CAUTION_LIST_FOR_EXPORTATION,
            ...generalCautionList,
          ];

    case transportCargoType === "Consol":
      return [...CONSOL_CAUTION_LIST, ...generalCautionList];

    default:
      return [];
  }
};

export { getCautionListByTransportCargoType };
